import { LocaleResource } from './en';

const pt = {
  main: {
    navbar: {
      home: 'Início',
      customers: 'Clientes',
      about: 'Sobre',
      developers: 'Desenvolvedores',
      language: 'Idioma',
    },
    hero: {
      topBadge: 'Soluções globais de pagamentos',
      header: 'Envie dinheiro para qualquer lugar',
      subHeading:
        'De dólares a euros, passando por pesos e muito mais, a Sphere facilita a movimentação entre moedas como nunca.',
      ctas: {
        primary: 'Para empresas',
        secondary: 'Para indivíduos',
      },
    },
    solutions: {
      header: {
        topBadge: 'Processamento de pagamentos otimizado',
        header: 'Pagamentos sem fronteiras para empresas',
        subHeading:
          'Movimente dinheiro de forma eficiente—de transferências bancárias a cartões, com suporte para todas as principais bandeiras—\ntransferir dinheiro online nunca foi tão simples.',
        ctas: {
          primary: 'Inicie agora',
          secondary: 'Descubra mais',
        },
        cards: {
          '0': {
            title: 'Remessas internacionais',
            description:
              'Envie qualquer moeda, e seus fornecedores recebem USD, MXN, BRL, EUR com taxas de câmbio imbatíveis.',
            bullets: {
              '0': 'Conforme às normas e seguro',
              '1': 'Liquidação quase instantânea',
              '2': 'Cobertura global',
            },
          },
          '1': {
            title: 'Para indivíduos com patrimônio elevado',
            description:
              'Serviços de OTC, conversões entre moeda simplificadas e soluções completas para rastreamento e gestão financeira.',
            bullets: {
              '0': 'Suporte dedicado 24/7',
              '1': 'Alta liquidez',
              '2': 'Privacidade e criptografia',
            },
          },
          '2': {
            title: 'Para Finanças e FinTechs',
            description:
              'Receba pagamentos como quiser, aproveite as conversões automáticas direto na sua conta, e ofereça serviços de conversão personalizados para seus clientes.',
            bullets: {
              '0': 'Acordos de nível de serviço exclusivos',
              '1': 'Proteção ao cliente em primeiro lugar',
              '2': 'API e Soluções No-Code',
            },
          },
        },
      },
    },
    ramp: {
      topBadge: 'Soluções de conversão',
      header: 'Conectando as moedas do mundo',
      subHeading:
        'Deixe a Sphere cuidar das complexidades de engenharia, operações e regulamentações — para que você possa se concentrar no que realmente importa.',
      solutions: {
        '0': {
          title: 'Sob medida para sua marca',
          description:
            'A Sphere gerencia tudo, desde a engenharia até a operação e regulamentação — para que você possa simplesmente desfrutar dos benefícios.',
          bullets: {
            '0': 'Disponível via API, no-code e widget incorporável.',
            '1': 'Ganhe mais tempo economizando horas de desenvolvimento.',
            '2': 'Preço imbatível',
          },
        },
        '1': {
          title: 'Conversão de moedas, fácil e rápido.',
          description:
            'Gere uma carteira vinculada à sua conta bancária. Envie moedas e receba USD com apenas um clique. Disponível para comerciantes selecionados através do painel Sphere.',
        },
        '2': {
          title: 'Compliance integrado',
          description:
            'Conte com nossa expertise global e licenças regulatórias para oferecer a melhor experiência de pagamento aos seus usuários. Deixe que cuidemos do monitoramento de transações, triagem de sanções e detecção de fraudes para você.',
        },
      },
    },
    dashboard: {
      topBadge: 'Funcionalidades',
      header: 'Painel Sphere',
      subHeading:
        'Os links de pagamento Sphere são versáteis, personalizáveis e robustos. Eles se adaptam às suas necessidades, seja para compras únicas ou assinaturas recorrentes.',
      solutions: {
        '0': {
          title: 'Links de pagamento',
          description:
            'Aceite pagamentos diretamente por e-mail, SMS, redes sociais, site ou app. Aproveite todo o poder da Sphere sem precisar escrever uma única linha de código.',
        },
        '1': {
          title: 'Assinaturas',
          description:
            'Ofereça assinaturas tradicionais ou autênticas on-chain — diretamente do cartão, conta bancária ou carteira digital. Faturamento flexível, desde por minuto até anual, medido por assento, e de volume a níveis graduados. Receba pagamentos diretamente, sem intermediários, desbloqueio de tokens, relayers de gas ou outros passos desnecessários.',
        },
        '2': {
          title: 'Ferramentas de desenvolvimento',
          description:
            'A Sphere simplifica o seu processo de engenharia com funcionalidades de pagamento integradas. Nós lidamos com as complexidades econômicas globais, permitindo que suas equipes foquem no desenvolvimento eficiente em uma plataforma unificada.',
        },
        '3': {
          title: 'Análises e insights de clientes',
          description:
            'Acompanhe mais de 15 KPIs, como MRR, churn, taxas de conversão e cliques. Maximize seus ganhos, aumente as taxas de aprovação, receba pagamentos mais rapidamente e reduza estornos aceitando uma maior variedade de pagamentos.',
        },
      },
    },
    developerTools: {
      topBadge: 'SDK',
      header: 'Kit de ferramentas para devs',
      subHeading:
        'Seguimos as melhores práticas em pagamentos para oferecer SDKs semânticos e fáceis de usar. Concentre-se no crescimento do seu negócio enquanto cuidamos das auditorias de contratos inteligentes, orquestração cross-chain e toda a manutenção técnica, operacional e de conformidade.',
      bullets: {
        '0': 'API RESTful simples, poderosa e intuitiva',
        '1': 'Código pronto para copiar-e-colar',
        '2': 'Documentação clara e completa',
      },
      ctas: {
        primary: 'Documentação',
        secondary: 'Suporte ao dev',
      },
    },
    features: {
      header: {
        topBadge: 'Funcionalidades',
        title: 'Uma suíte de pagamentos completa',
        description:
          'Conecte-se perfeitamente através de uma única API ou um console fácil de usar em minutos.\nAcesse conformidade de alto nível, contabilidade, pagamentos, on/off-ramps, análises e ferramentas para desenvolvedores — tudo em um só lugar.',
      },
      grid: {
        '0': 'Análises completas',
        '1': 'Pagamentos e recebimentos multipartes',
        '2': 'Construção colaborativa com ACLs baseados em funções',
        '3': 'Detecção avançada de AML e fraude',
        '4': 'Segurança certificada',
        '5': 'Notificações por email',
      },
      securityMarquees: {
        '0': 'Sanções\nOFAC',
        '1': 'Práticas de segurança de FTC',
        '2': 'Práticas de segurança de GDPR',
        '3': 'Compatível com\nPCI',
      },
      benefits: {
        lowestFees: {
          title: 'Menores taxas do mercado',
          description:
            'Todas as transações com taxas equiparadas às da concorrência, com taxas de rede repassadas ao custo.',
        },
        convenientSwaps: {
          title: 'Swaps convenientes',
          description:
            'Pagamentos diretamente na sua conta bancária ou na de um fornecedor terceirizado, de forma manual ou agendada, com taxas competitivas de nível OTC.',
        },
        instantSettlements: {
          title: 'Liquidações instantâneas',
          description:
            'Receba seu dinheiro em segundos ou minutos, em vez de dias ou semanas.',
        },
        splitPayouts: {
          title: 'Pagamentos divididos',
          description:
            'Divida os pagamentos automaticamente sem taxas adicionais.',
        },
        nonCustodial: {
          title: 'Não custodial',
          description:
            'Os pagamentos são ponto a ponto e nunca manipulamos fundos de clientes ou comerciantes.',
        },
        audited: {
          title: 'Auditado',
          description:
            'Nossos contratos são auditados pelos melhores especialistas do setor. Regularmente realizamos modelagem de ameaças, testes de penetração e avaliações de vulnerabilidades em nossa infraestrutura.',
        },
        compliant: {
          title: 'Compliance',
          description:
            'A Sphere segue todas as melhores práticas de acordo com as regulamentações dos EUA e utiliza transmissores de dinheiro licenciados.',
        },
        encrypted: {
          title: 'Criptografado',
          description:
            'Todos os dados são criptografados e/ou tokenizados em repouso e em trânsito. Nunca armazenamos PII sensível e temos ACLs rígidos.',
        },
      },
    },
    ctaSection: {
      header: 'Pronto para dar o próximo passo?',
      description:
        'Venha fazer parte dos líderes da indústria que já estão crescendo com a Sphere.',
      button: 'Começar',
    },
    faq: {
      header: 'FAQ',
      description:
        'Perguntas frequentes. Não encontrou a resposta que procurava? Entre em contato.',
      questions: {
        '0': {
          q: 'Quanto custa para usar a Sphere?',
          a: 'O acesso ao software é gratuito, mas cobramos uma pequena taxa por transação. Preços personalizados para empresas estão disponíveis mediante solicitação.',
        },
        '1': {
          q: 'Quais redes são suportadas?',
          a: 'Suportamos todas as principais redes de cartões (Visa, Mastercard, American Express, Discover) em mais de 120 países, transferências bancárias e transferências nacionais e internacionais.',
        },
        '2': {
          q: 'Como é realizada a conversão de moedas?',
          a: 'Os clientes consentem em trocar para uma moeda diferente durante a transferência, que é roteada por meio de uma exchange ou sujeita a taxas de câmbio ao custo.',
        },
        '3': {
          q: 'A Sphere é auditada?',
          a: 'Sim, somos auditados pela OtterSec e estamos em conformidade com PCI-DSS. Voluntariamente atendemos aos requisitos de MSB/VASP e implementamos um robusto programa de KYC/AML. Estamos em processo de obter as certificações SOC2 e ISO/IEC 27001.',
        },
        '4': {
          q: 'A Sphere faz custódia de fundos?',
          a: 'Nunca. Somos um provedor de serviços tecnológicos, não um MSB/VASP nem um transmissor de dinheiro. Todos os pagamentos que envolvem custódia são tratados por transmissores de dinheiro licenciados, custodianos qualificados e bancos autorizados.',
        },
        '5': {
          q: 'É possível integrar a Sphere com minha solução de pagamento existente?',
          a: 'Sim. Você pode usar seu próprio Stripe ou outros provedores de pagamento e usar a Sphere para adicionar suporte a pagamentos alternativos e automação de back-office por meio de nossa API/SDK. Projetamos nossa API para utilizar fluxos de dados e modelos de objetos semelhantes, reduzindo a quantidade de infraestrutura a ser reescrita.',
        },
      },
    },
    footer: {
      slogan:
        'De dólares e euros a pesos e muito mais, o Sphere facilita a movimentação entre moedas.',
      termsOfService: 'Termos de Serviço & Política de Privacidade',
      privacyPolicy: 'Política de Privacidade',
      reportVulnerability: 'Reportar uma vulnerabilidade',
    },
  },
  accessRequest: {
    steps: {
      '1': {
        title: 'Solicitar Acesso',
        descriptionParagraph1:
          'Para <0>indivíduos</0> que desejam utilizar nossa funcionalidade de on- e off- ramping, agora é possível fazer isso sem precisar nos contatar. <0>Basta visitar</0> <1>spherepay.co/ramp</1> para se registrar e começar.',
        descriptionParagraph2:
          'Para empresas ou outras solicitações, por favor, complete as informações abaixo e um especialista da Sphere entrará em contato em breve.',
        form: {
          walletAddress: 'Endereço da Carteira',
          firstName: 'Nome',
          lastName: 'Sobrenome',
          email: 'Email de Trabalho',
          businessName: 'Nome da Empresa',
          businessUrl: 'URL do Site da Empresa',
          phoneNumber: 'Número de Telefone',
        },
        notYourEmail: 'Não é seu email?',
        errors: {
          emailAlreadyInUse:
            'Este email já está em uso para uma solicitação ativa. Por favor, tente outro.',
          emailAlreadyLinkedToAccount:
            'Este email já está vinculado a uma conta. Prefere iniciar sessão?',
        },
      },
      '2': {
        title: 'Informações de Contato',
        form: {
          preferredContactMethod: 'Método de Contato Preferido',
          preferredContactMethodDescription:
            'Os detalhes de acesso à conta serão enviados por e-mail.',
          placeholders: {
            telegram: '@nomedeusuario',
            whatsapp: '+1234567890',
            email: 'voce@exemplo.com',
            sms: '+1234567890',
          },
        },
      },
      '3': {
        title: 'Detalhes do Negócio',
        form: {
          bankAccountJurisdiction:
            'Seu negócio possui uma conta bancária em algum dos seguintes países?',
          bankAccountJurisdictionsOptions: {
            unitedStates: 'Estados Unidos (USD)',
            europeanUnion: 'União Europeia (EUR)',
            noneOfTheAbove: 'Nenhuma das opções acima',
          },
          preferredCurrencies:
            'Quais moedas você gostaria de usar para transações de on- e off- ramping?',
          preferredCurrenciesDescription:
            'Por favor, selecione pelo menos uma.',
          otherCurrencyPlaceholder: 'Por favor, especifique',
        },
      },
      '4': {
        title: 'Detalhes do Negócio',
        submitApplication: 'Enviar solicitação',
        form: {
          businessOperatingAddress: 'Endereço Operacional do Negócio',
          businessCountry: 'Em qual país seu negócio está localizado?',
          countryPlaceholder: 'País',
          statePlaceholder: 'Estado',
          monthlyTransferVolume: 'Volume de Transferência Mensal',
          estimatedTransactionVolume:
            'Qual foi o volume estimado de transações no mês passado?',
          selectPlaceholder: 'Por favor, selecione',
          monthlyVolume: {
            '0_4999': 'Menos de $4.999',
            '5000_9999': '$5.000 - $9.999',
            '10000_99999': '$10.000 - $99.999',
            '100000_499999': '$100.000 - $499.999',
            '500000_999999': '$500.000 - $999.999',
            '1000000_5000000': '$1.000.000 - $5.000.000',
            '5000000_plus': 'Mais de $5.000.000',
          },
          additionalDetails: 'Detalhes Adicionais',
          additionalDetailsDescription:
            'Descreva a natureza do negócio e seus objetivos para a conta.',
        },
      },
    },
    submitted: {
      title: 'Seu cadastro foi\nenviado com sucesso',
      defaultBody:
        'Obrigado pelo seu interesse. Nossa equipe revisará suas informações e entrará em contato <0>por email</0> se pudermos conceder acesso a uma conta.',
      bridgeBody:
        'Obrigado pelo seu interesse. Nossa equipe revisará suas informações e entrará em contato <0>por email</0> quando pudermos atendê-lo.',
      conciergeBody:
        'Ótimo! Alguém da nossa equipe entrará em contato em breve. Se houver qualquer atraso, sinta-se à vontade para nos contatar diretamente aqui:',
      returnToWebsite: 'Voltar ao site',
      contactUs: 'Conectar com seu especialista de onboarding',
    },
    googleSplash: {
      pendingTitle: 'Sua conta ainda está\npendente de aprovação',
      noAccountTitle: 'Este Gmail não está associado\na uma conta Sphere',
      applicationId: 'Seu ID de Aplicação:',
      pendingDescription:
        'Recebemos sua solicitação Sphere e estamos processando seu pedido. Entraremos em contato em breve com uma atualização sobre o status da sua conta o mais rápido possível.',
      noAccountDescription:
        'Este Gmail não está vinculado a uma conta Sphere.\nPreencha uma solicitação para começar.',
      returnToLogin: 'Voltar ao login',
      fillOutApplication: 'Preencher solicitação',
    },
    loadingAnimation: {
      calculatingRates: 'Calculando as melhores taxas de câmbio...',
      securingTransactions: 'Protegendo suas transações...',
      optimizingPayments: 'Otimizando pagamentos globais...',
      connectingCurrencies: 'Conectando moedas...',
      ensuringCompliance: 'Garantindo padrões de conformidade...',
      processingData: 'Processando dados com segurança...',
      almostThere: 'Quase lá...',
    },
    passwordSetup: {
      loginSuccess: 'Login bem-sucedido! Bem-vindo de volta {{email}}',
      newLinkSent: 'Um novo link foi enviado para o seu e-mail.',
      regenerateError:
        'Ocorreu um erro ao regenerar o token de acesso. Por favor, tente novamente.',
      tokenNotFoundOrInvalid: 'Token não encontrado ou inválido',
      tokenNotFoundOrInvalidDescription:
        'O token de acesso que você está tentando usar não foi encontrado ou é inválido. Isso pode ser devido à expiração ou a um erro no link.',
      returnToWebsite: 'Voltar ao site',
      contactSupport: 'Contatar suporte',
      linkExpired: 'Seu link expirou',
      linkExpiredDescription:
        'O link do e-mail expirou. Por favor, gere um novo para completar seu cadastro.',
      redirecting_zero: 'Redirecionando você agora...',
      redirecting_one: 'Redirecionando você em 1 segundo...',
      redirecting_other: 'Redirecionando você em {{count}} segundos...',
      regenerateLink: 'Regenerar Link',
      createSignIn: 'Criar Login',
      createAccount: 'Criar conta',
      email: 'E-mail',
      password: 'Senha',
      enterPassword: 'Digite a Senha',
      confirmPassword: 'Confirme a Senha',
    },
  },
  validation: {
    required: 'Obrigatório',
    invalidEmail: 'Por favor, insira um e-mail válido',
    emailTooLong: 'Máximo: 255 caracteres',
    firstNameTooLong: 'Máximo: 50 caracteres',
    lastNameTooLong: 'Máximo: 50 caracteres',
    businessNameTooLong: 'Máximo: 50 caracteres',
    businessUrlTooLong: 'Máximo: 255 caracteres',
    phoneNumberTooLong: 'Máximo: 20 caracteres',
    preferredContactMethodValueTooLong: 'Máximo: 255 caracteres',
    weakPassword:
      'A senha deve ter pelo menos 6 caracteres e conter pelo menos uma letra e um número',
    passwordsDoNotMatch: 'As senhas não coincidem',
  },
  general: {
    comingSoon: 'Em breve',
    support: 'Suporte',
    optional: 'Opcional',
    back: 'Voltar',
    next: 'Próximo',
    continue: 'Continuar',
    yes: 'Sim',
    no: 'Não',
    other: 'Outro',
    selectAllThatApply: 'Selecione todos que se aplicam',
    unknownError:
      'Ocorreu um erro desconhecido. Por favor, tente novamente mais tarde.',
  },
} as const satisfies LocaleResource;

export default pt;
